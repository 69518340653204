<template>
  <v-container>
    <div class="header">
      <title-vue title="Detalhes do Onboarding" />
      <status-badge-vue :status="partner.status" />
    </div>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <p class="subtitle-2">Dados principais</p>
            <v-row>
              <v-col cols="12" md="4">
                <title-subtitle-vue
                  :label="partner.typeCompany == 'PJ' ? 'Razão social' : 'Nome completo'"
                  :value="partner.name"
                />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue
                  :label="partner.typeCompany == 'PJ' ? 'Nome fantasia' : 'Como quer ser chamado'"
                  :value="partner.nickname || partner.name"
                />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue
                  :label="partner.typeCompany == 'PJ' ? 'CNPJ' : 'CPF'"
                  :value="formatDocument(partner.document)"
                />
              </v-col>
              <v-col cols="12" md="4" v-if="partner.typeCompany === 'PJ'">
                <title-subtitle-vue label="CNAE" :value="partner.cnaeCode" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Categoria do parceiro" :value="partner.category.name" />
              </v-col>

              <v-col cols="4">
                <title-subtitle-vue label="Email" :value="partner.email" />
              </v-col>
              <v-col cols="4">
                <title-subtitle-vue label="Telefone" :value="formatPhone(partner.phone)" />
              </v-col>

              <v-col cols="12" md="4">
                <title-subtitle-vue
                  label="Data da solicitação"
                  :value="formatDate(partner.createdAt)"
                />
              </v-col>
            </v-row>
            <hr />
            <p class="subtitle-2 mt-4">Dados de endereço</p>
            <v-row>
              <v-col cols="4">
                <title-subtitle-vue label="Logradouro" :value="partner.address.street" />
              </v-col>
              <v-col cols="2">
                <title-subtitle-vue label="Número" :value="partner.address.addressNumber" />
              </v-col>
              <v-col cols="3">
                <title-subtitle-vue label="Bairro" :value="partner.address.neighborhood" />
              </v-col>
              <v-col cols="3">
                <title-subtitle-vue label="Complemento" :value="partner.address.complement" />
              </v-col>
              <v-col cols="4">
                <title-subtitle-vue label="CEP" :value="formatZipCod(partner.address.zipCode)" />
              </v-col>
              <v-col cols="4">
                <title-subtitle-vue label="Cidade" :value="partner.address.cityName" />
              </v-col>
              <v-col cols="4">
                <title-subtitle-vue label="Estado" :value="partner.address.state" />
              </v-col>
            </v-row>
            <hr />
            <p class="subtitle-2 mt-4">Dados do usuário</p>
            <v-row>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Nome" :value="formatDocument(partner.user.name)" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Email" :value="partner.user.email" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Telefone" :value="formatPhone(partner.user.phone)" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="CPF" :value="formatDocument(partner.user.document)" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Data de nascimento" :value="partner.user.birthdate" />
              </v-col>
            </v-row>

            <hr />

            <p class="subtitle-2 mt-4">Dados bancários</p>
            <v-row>
              <v-col cols="2">
                <title-subtitle-vue
                  label="Número da conta EFI (sem o hifen)"
                  :value="partner.digitalAccountNumber"
                />
              </v-col>
              <v-col cols="6">
                <title-subtitle-vue label="Chave PIX" :value="partner.pixkey" />
              </v-col>
              <v-col cols="12" md="6">
                <title-subtitle-vue label="Taxa administrativa" :value="`${partner.tax}%`" />
              </v-col>
            </v-row>
            <hr />
            <p class="subtitle-2 mt-4">Dados de funcionamento</p>
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headersBusinessHours"
                  :items="partner.businessHours"
                  :items-per-page="5"
                  :disabled="loading"
                  class="elevation-1"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <td class="text-center">{{ item.day }}</td>
                        <td class="text-center">{{ item.startTime }}</td>
                        <td class="text-center">{{ item.endTime }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <p class="subtitle-2 mt-4">Documentos da empresa</p>
            <v-row>
              <v-col cols="12" md="12">
                <v-btn @click="handlePdfClick(partner.contractFile)" text color="primary"
                  >Contrato Social</v-btn
                >
                <v-btn @click="handlePdfClick(partner.registrationStatusFile)" text color="primary"
                  >Situação cadastral</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-end">
        <button-vue dark color="red" class="mr-4" type="repprove" @click="handleReprove"
          >Reprovar</button-vue
        >
        <button-vue dark color="green" type="primary" @click="handleAprove">Aprovar</button-vue>
      </v-col>
    </v-row>
    <v-dialog v-model="showImageDialog" width="100%" height="600px">
      <v-card>
        <v-img :src="clickedImage" contain></v-img>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showPdfDialog">
      <v-card>
        <object :data="clickedPdf" type="application/pdf" width="100%" height="600px"></object>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ButtonVue from "@/components/button/Button.vue";
import TitleVue from "@/components/title/Title.vue";
import TitleSubtitleVue from "@/components/titleSubtitle/TitleSubtitle.vue";
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";

import { GetOnboardingPartner } from "@/services/onboarding-partner/GetOnboardingPartner";
import { UpdateOnboardingPartner } from "@/services/onboarding-partner/UpdateOnboarding";
import { PartnerDetailsType } from "@/types/partner/Partner";

import { format } from "date-fns";
export default defineComponent({
  components: { ButtonVue, TitleVue, TitleSubtitleVue, StatusBadgeVue },
  props: ["mode", "pClient"],
  data() {
    return {
      loading: false,
      showImageDialog: false,
      clickedImage: "",
      showPdfDialog: false,
      clickedPdf: "",
      partner: {
        id: "",
        name: "",
        status: "PENDING",
        nickname: "",
        tax: 5,
        typeCompany: "PJ",
        category: {
          name: "",
          id: "",
        },
        phone: "",
        email: "",
        document: "",
        pixkey: "",
        cnaeCode: "",
        address: {
          state: "",
          street: "",
          addressNumber: "",
          neighborhood: "",
          complement: "",
          zipCode: "",
          cityName: "",
          ibge: "",
        },
        users: [],
        user: {
          name: "",
          email: "",
          phone: "",
          document: "",
          password: "",
          nickname: "",
          birthdate: "",
          motherName: "",
        },
        associate: [],
        businessHours: [],
        createdAt: "",
        digitalAccountNumber: "",
        digitalAccountDocument: "",
        associates: [],
        categoryId: "1",
        contractFile: "",
        registrationStatusFile: "",
      } as PartnerDetailsType,
      headersAssociates: [
        {
          text: "Nome",
          align: "center",
          value: "name",
        },
        {
          text: "CPF",
          align: "center",
          value: "cpf",
          textAlign: "center",
        },
        {
          text: "RG",
          align: "center",
          value: "rg",
        },
        {
          text: "Documento FRENTE",
          align: "center",
          value: "frontDocumentImage",
        },
        {
          text: "Documento COSTAS",
          align: "center",
          value: "backDocumentImage",
        },
        {
          text: "Selfie",
          align: "center",
          value: "selfImage",
        },
      ],
      headersBusinessHours: [
        {
          text: "Dia",
          align: "center",
          value: "day",
        },
        {
          text: "Abertura",
          align: "center",
          value: "startTime",
        },
        {
          text: "Fechamento",
          align: "center",
          value: "endTime",
        },
      ],
    };
  },
  methods: {
    async handleLoadOnboarding() {
      const getOnboarding = new GetOnboardingPartner();
      const onboarding = await getOnboarding.execute(this.$route.params.id);
      onboarding.document.length > 11
        ? (this.partner.typeCompany = "PJ")
        : (this.partner.typeCompany = "PF");
      this.partner.id = onboarding.id || "";
      this.partner.name = onboarding.name || "";
      this.partner.nickname = onboarding.nickname || "";
      this.partner.document = onboarding.document || "";
      this.partner.cnaeCode = onboarding.cnaeCode || "";
      this.partner.category.name = onboarding.category.name || "";
      this.partner.tax = onboarding.tax || 0;
      this.partner.status = onboarding.status;
      this.partner.createdAt = onboarding.createdAt || "";
      this.partner.digitalAccountDocument = onboarding.digitalAccountDocument || "";
      this.partner.digitalAccountNumber = onboarding.digitalAccountNumber || "";
      this.partner.pixkey = onboarding.pixkey || "";
      this.partner.phone = onboarding.phone || "";
      this.partner.email = onboarding.email || "";
      this.partner.address.street = onboarding.address.street || "";
      this.partner.address.addressNumber = onboarding.address.addressNumber || "";
      this.partner.address.neighborhood = onboarding.address.neighborhood || "";
      this.partner.address.complement = onboarding.address.complement || "";
      this.partner.address.zipCode = onboarding.address.zipCode || "";
      this.partner.address.cityName = onboarding.address.cityName || "";
      this.partner.address.state = onboarding.address.state || "";
      this.partner.user = onboarding.users[0];
      this.partner.associates = onboarding.associates || [];
      this.partner.businessHours = onboarding.businessHours || [];
      this.partner.contractFile = onboarding.contractFile || "";
      this.partner.registrationStatusFile = onboarding.registrationStatusFile || "";
    },
    async handleAprove() {
      const updateOnboarding = new UpdateOnboardingPartner();
      updateOnboarding
        .execute(this.$route.params.id, "ACTIVE")
        .then(() => {
          this.$store.dispatch("showSnack", {
            message: "Conta aprovada com sucesso",
            color: "green darken-1",
          });
          this.partner.status = "ACTIVE";
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao aprovar a conta",
            color: "red",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleReprove() {
      const updateOnboarding = new UpdateOnboardingPartner();
      updateOnboarding
        .execute(this.$route.params.id, "PENDING")
        .then(() => {
          this.$store.dispatch("showSnack", {
            message: "Conta reprovada com sucesso",
            color: "green darken-1",
          });
          this.partner.status = "PENDING";
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao reprovar a conta",
            color: "red",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.$router.push("/onboarding");
    },
    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } else if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      } else {
        return document;
      }
    },
    handleImageClick(imageUrl: string) {
      this.clickedImage = imageUrl;
      this.showImageDialog = true;
    },
    handlePdfClick(pdfUrl: string) {
      this.clickedPdf = pdfUrl;
      this.showPdfDialog = true;
    },
  },
  mounted() {
    this.handleLoadOnboarding();
  },
});
</script>
<style scoped>
.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}
.img-document {
  cursor: zoom-in;
}
</style>
