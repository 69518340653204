import api from "@/services/api";

import { FranchiseType } from "@/types/franchise/Franchise";

class GetAllFranchises {
  execute = async () => {
    const franchises = await api().get(`/franchises`);

    return franchises.data.franchises as FranchiseType[];
  };
}

export { GetAllFranchises };
