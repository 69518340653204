import api from "@/services/api";

interface distributionRequest {
  distributionPercent: number;
  id: string;
}

class UpdateDistribution {
  execute = async (distributions: distributionRequest[]) => {
    const result = await api().patch(`/distributions/update`, {
      distributions: distributions,
    });
    return result;
  };
}

export { UpdateDistribution };
