<template>
  <div>
    <div class="header">
      <title-vue title="Lista de onboarding" />
    </div>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquise pelo nome, CPF, email ou telefone"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              :items="status"
              v-model="statusFiltered"
              item-text="title"
              item-value="value"
              label="Status"
              @change="handleLoadOnboardings"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :header-props="{ sortByText: 'Filtrar por' }"
        :headers="headers"
        :items="onboardings"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items-per-page="10"
        :footer-props="{ 'items-per-page-text': 'onboardings por página' }"
        :search="search"
        no-data-text="Nenhum onboarding encontrado"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ item.name }}</td>
              <td class="d-block d-sm-table-cell">{{ formatDocument(item.document) }}</td>
              <td class="d-block d-sm-table-cell">
                {{ phoneNumberFormat(item.phone) }}
              </td>
              <td class="d-block d-sm-table-cell">{{ item.email }}</td>
              <td class="d-block d-sm-table-cell">{{ item.cityName }}</td>
              <td class="d-block d-sm-table-cell">{{ formatDate(item.createdAt) }}</td>
              <td class="d-block d-sm-table-cell"><status-badge-vue :status="item.status" /></td>
              <td class="d-block d-sm-table-cell">
                <v-icon size="24" color="#e5183d" @click="handleDetailOnboarding(item.id)"
                  >mdi-menu</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TitleVue from "@/components/title/Title.vue";
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";

import { OnboardingType } from "@/types/onboarding/Onboarding";
import { GetAllOnbordings } from "@/services/onboardings/GetAllOnboardings";
import { format } from "date-fns";

export default defineComponent({
  components: { TitleVue, StatusBadgeVue },

  data() {
    return {
      mobileBreakpoint: 600,

      loading: true,
      showModal: false,
      showModalRemove: false,
      modeForm: "",
      onboardings: [] as OnboardingType[],
      search: "",
      sortBy: "createdAt",
      sortDesc: false,
      statusFiltered: "WAITING",
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        { text: "CPF", value: "document" },
        { text: "Telefone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Cidade", value: "cityName" },
        { text: "Data da solicitação", value: "createdAt" },
        { text: "Status", value: "status", align: "center" },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
        },
      ],
      status: [
        {
          title: "Pendente",
          value: "PENDING",
        },
        {
          title: "Pré-registrado",
          value: "PRE_REGISTRATION",
        },
        {
          title: "Ativo",
          value: "ACTIVE",
        },
        {
          title: "Bloqueado",
          value: "BLOCKED",
        },
        {
          title: "Cancelado",
          value: "CANCELED",
        },
        {
          title: "Aguardando",
          value: "WAITING",
        },
      ],
    };
  },
  methods: {
    async handleLoadOnboardings() {
      this.loading = true;
      const getAllOnboardings = new GetAllOnbordings();
      const onboardings = await getAllOnboardings.execute(this.statusFiltered);
      this.onboardings = onboardings;
      this.loading = false;
    },
    handleDetailOnboarding(id: string) {
      this.$router.push(`/onboarding/detail/${id}`);
    },
    phoneNumberFormat: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },
  mounted() {
    this.handleLoadOnboardings();
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
