import api from "@/services/api";


class GetOnboardingPartner {
    execute = async (id: string) => {
        const partnerOnboarding = await api().get(`/partner/${id}`)

        return partnerOnboarding.data
    }
    
}

export { GetOnboardingPartner }