import api from "@/services/api";
import { OnboardingType } from "@/types/onboarding/Onboarding";

class GetAllOnbordings {
  execute = async (status: string, ibge?: number) => {
    const onboardings = await api().get(`/customers`, {
      params: {
        status: status,
        ibge: ibge,
      },
    });
    return onboardings.data as OnboardingType[];
  };
}

export { GetAllOnbordings };
