<template>
  <v-container class="custom-container">
    <v-row class="custom-row">
      <div class="background-col" :style="{ background: backgroundColor }"></div>

      <v-col class="text-col">
        <v-row class="title-row">
          <v-col>
            <v-text class="title-text mr-4" :style="{ fontSize: '12px', color: '#757575' }">{{
              title1
            }}</v-text>
          </v-col>
        </v-row>

        <v-row class="title-row">
          <v-col>
            <v-text class="title-text" :style="{ fontSize: '24px', color: '#000000' }">{{
              title2
            }}</v-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    backgroundColor: String,
    title1: String,
    title2: String,
  },
};
</script>

<style scoped>
.custom-container {
  height: 100px;
  min-width: 250px;
  padding: 0;
  background-color: #fafafa !important;
}

.custom-row {
  height: 100%;
}

.background-col {
  width: 10px;
}

.text-col {
  width: 140px;
  height: 100%;
}

.title-row {
  height: 50%;
  align-items: center;
  justify-content: center;
}
</style>
