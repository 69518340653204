import api from "@/services/api";

import { FranchiseeDetailsType } from "@/types/franchisee/Franchisee";

class CreateFranchisee {
  execute = async (franchisee: FranchiseeDetailsType) => {
    const franchiseeSend = await api().post(`/franchise-user`, {
      name: franchisee.name,
      phone: franchisee.phone,
      email: franchisee.email,
      document: franchisee.document,
      franchises: franchisee.franchises,
    });
    return franchiseeSend.data as FranchiseeDetailsType;
  };
}

export { CreateFranchisee };
