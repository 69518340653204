import api from "@/services/api";
import { ProductSignature } from "@/types/products/Product";

class GetProductSignature {
  execute = async (id: string) => {
    const product = await api().get(`/products/${id}/signatures`);
    return product.data as ProductSignature[];
  };
}

export { GetProductSignature };
