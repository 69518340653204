import api from "@/services/api";

import { PhasesListType } from "@/types/phase/Phases";
class GetAllPhases {
  execute = async () => {
    const phases = await api().get(`/distributions/list`);
    return phases.data.distributions as PhasesListType[];
  };
}

export { GetAllPhases };
