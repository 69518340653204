import api from "@/services/api";

import { PhaseSystemLiveType } from "@/types/phase/Phases";
class GetPhasesLive {
  execute = async () => {
    const phases = await api().get(`/phases/count-users`);
    return phases.data as PhaseSystemLiveType[];
  };
}

export { GetPhasesLive };
