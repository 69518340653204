<template>
  <div>
    <div class="header">
      <title-vue title="Lista de franqueados" />
      <v-btn dark color="#e5183d" @click="handleNewFranchisee()">Novo franqueado</v-btn>
    </div>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquise pelo nome, cidade, estado ou telefone"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :header-props="{ sortByText: 'Filtrar por' }"
        :headers="headers"
        :items="franchisees"
        :items-per-page="10"
        :footer-props="{ 'items-per-page-text': 'franqueados por página' }"
        :search="search"
        no-data-text="Nenhum franqueado encontrado"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ item.name }}</td>
              <td class="d-block d-sm-table-cell">{{ formatDocument(item.document) }}</td>
              <td class="d-block d-sm-table-cell">{{ item.email }}</td>
              <td class="d-block d-sm-table-cell">{{ phoneNumberFormat(item.phone) }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TitleVue from "@/components/title/Title.vue";

import { FranchiseeType } from "@/types/franchisee/Franchisee";
import { GetAllFranchisees } from "@/services/franchisees/GetAllFranchisees";
import { format } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  createdAt: string;
}

export default defineComponent({
  components: { TitleVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      showModal: false,
      showModalRemove: false,

      franchisees: [] as FranchiseeType[],
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        { text: "CPF", value: "document" },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "phone" },
      ],
    };
  },
  methods: {
    async handleLoadFranchisees() {
      this.loading = true;
      const getAllFranchisees = new GetAllFranchisees();
      const franchisees = await getAllFranchisees.execute();

      this.franchisees = franchisees;
      this.loading = false;
    },
    handleNewFranchisee() {
      this.$router.push({
        name: "franchiseeForm",
        params: { modeForm: "new" },
      });
    },
    phoneNumberFormat: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },
  mounted() {
    this.handleLoadFranchisees();
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
