<template>
  <v-container>
    <v-template>
      <div class="header">
        <title-vue :title="title" />
      </div>

      <v-row class="mt-2">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
                <p class="subtitle-2">Dados da franquia</p>
                <v-row>
                  <v-col cols="12" md="6">
                    <input-text-vue
                      label="Nome *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchise.name"
                    />
                  </v-col>

                  <v-col cols="12" md="3">
                    <input-text-vue
                      label="CNPJ *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchise.document"
                      v-mask="'##.###.###/####-##'"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <input-text-vue
                      label="Telefone *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchise.phone"
                      v-mask="'(##) #####-####'"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <input-text-vue
                      label="Email *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchise.email"
                    />
                  </v-col>
                </v-row>
                <p class="subtitle-2">Dados bancários</p>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Número da conta EFI (sem o hifen) *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchise.digitalAccountNumber"
                      @keydown="handleBloquedNumber($event)"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <input-text-vue
                      label="Chave PIX *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchise.pixkey"
                    />
                  </v-col>
                </v-row>
                <p class="subtitle-2">Dados de endereço</p>
                <v-row>
                  <v-col cols="2">
                    <v-text-field
                      label="CEP *"
                      required
                      :disabled="loading"
                      v-model="franchise.address.zipCode"
                      v-mask="'#####-###'"
                      :readonly="readonly"
                      :rules="rules"
                      @blur="handleAddress"
                    />
                  </v-col>
                  <v-col cols="4">
                    <input-text-vue
                      label="Logradouro *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchise.address.street"
                    />
                  </v-col>
                  <v-col cols="2">
                    <input-text-vue
                      label="Número"
                      :disabled="loading"
                      :readonly="readonly"
                      v-model="franchise.address.addressNumber"
                    />
                  </v-col>
                  <v-col cols="4">
                    <input-text-vue
                      label="Complemento"
                      :disabled="loading"
                      :readonly="readonly"
                      v-model="franchise.address.complement"
                    />
                  </v-col>
                  <v-col cols="4">
                    <input-text-vue
                      label="Bairro *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchise.address.neighborhood"
                    />
                  </v-col>
                  <v-col cols="2">
                    <input-text-vue
                      label="UF *"
                      :required="true"
                      :disabled="true"
                      :readonly="true"
                      :rules="rules"
                      v-model="franchise.address.state"
                    />
                  </v-col>
                  <v-col cols="4">
                    <input-text-vue
                      label="Cidade *"
                      :required="true"
                      :disabled="true"
                      :readonly="true"
                      :rules="rules"
                      v-model="franchise.address.cityName"
                    />
                  </v-col>
                </v-row>
                <p class="subtitle-2" v-if="$route.params.id">Dados do franqueado responsável</p>
                <v-row v-if="$route.params.id">
                  <v-col cols="6">
                    <input-text-vue
                      label="Nome completo"
                      :disabled="loading"
                      readonly
                      v-model="franchise.primaryFranchisee.name"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Telefone"
                      :disabled="loading"
                      v-model="franchise.primaryFranchisee.phone"
                      v-mask="'(##) #####-####'"
                      readonly
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!franchise.id && !loading">
        <v-col class="d-flex justify-end">
          <button-vue dark class="mr-4" type="cancel" @click="handleCancel">Cancelar</button-vue>
          <button-vue
            dark
            :type="!valid ? 'default' : 'primary'"
            :disabled="!valid || loading"
            @click="handleSave"
            >Salvar</button-vue
          >
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="d-flex justify-start">
          <button-vue dark class="mr-4" type="cancel" @click="handleCancel">Voltar</button-vue>
        </v-col>
      </v-row>
    </v-template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ButtonVue from "@/components/button/Button.vue";
import TitleVue from "@/components/title/Title.vue";
import InputTextVue from "@/components/inputs/InputText.vue";

import { CreateFranchise } from "@/services/franchises/CreateFranchise";
import { GetFranchise } from "@/services/franchises/GetFranchise";
import { GetAddress } from "@/services/viaCep/GetAddress";

import { format } from "date-fns";
import { FranchiseDetailsType } from "@/types/franchise/Franchise";
import { Status } from "@/types/franchise/Status";
export default defineComponent({
  components: { ButtonVue, TitleVue, InputTextVue },
  data() {
    return {
      loading: false,
      title: "Nova franquia",
      valid: true,
      readonly: false,
      franchise: {
        id: "",
        name: "",
        tax: 5,
        phone: "",
        email: "",
        document: "",
        pixkey: "",
        status: "" as Status,
        address: {
          state: "",
          street: "",
          addressNumber: "",
          neighborhood: "",
          complement: "",
          zipCode: "",
          cityName: "",
          ibge: "",
        },
        primaryFranchisee: {
          name: "",
          phone: "",
        },
        createdAt: "",
        digitalAccountNumber: "",
        digitalAccountDocument: "",
      } as FranchiseDetailsType,
      rules: [(v: string) => !!v || "Este campo é obrigatório."],
    };
  },
  methods: {
    async handleLoadFranchise() {
      this.readonly = true;
      this.title = "Detalhes da franquia";
      this.loading = true;
      const getFranchise = new GetFranchise();
      const franchise = await getFranchise.execute(this.$route.params.id);

      this.franchise.id = franchise.id || "";
      this.franchise.name = franchise.name || "";

      this.franchise.createdAt = franchise.createdAt || "";
      this.franchise.phone = this.formatPhone(franchise.phone) || "";
      this.franchise.email = franchise.email || "";
      this.franchise.document = this.formatDocument(franchise.document) || "";
      this.franchise.status = franchise.status || null;

      this.franchise.address.street = franchise.address.street || "";
      this.franchise.address.addressNumber = franchise.address.addressNumber || "";
      this.franchise.address.neighborhood = franchise.address.neighborhood || "";
      this.franchise.address.complement = franchise.address.complement || "";
      this.franchise.address.zipCode = this.formatZipCod(franchise.address.zipCode) || "";
      this.franchise.address.cityName = franchise.address.cityName || "";
      this.franchise.address.state = franchise.address.state || "";

      this.franchise.primaryFranchisee.name = franchise.primaryFranchisee.name || "";
      this.franchise.primaryFranchisee.phone =
        this.formatPhone(franchise.primaryFranchisee.phone) || "";

      this.franchise.tax = franchise.tax || 5;
      this.franchise.pixkey = franchise.pixkey || "";
      this.franchise.digitalAccountNumber = franchise.digitalAccountNumber || "";
      this.franchise.digitalAccountDocument = franchise.digitalAccountDocument || "";
      this.loading = false;
    },
    async handleSave() {
      this.loading = true;
      const createFranchise = new CreateFranchise();
      await createFranchise
        .execute(this.franchise)
        .then(() => {
          this.$store.dispatch("showSnack", {
            message: "Franquia cadastrada com sucesso",
            color: "green darken-1",
          });
          this.$router.push("/franchises");
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao cadastrar a franquia",
            color: "red",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.$router.push("/franchises");
    },
    handleBloquedNumber(e: any) {
      const allowedKeys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
      ];

      if (!allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
    },
    async handleAddress() {
      const getAddress = new GetAddress();
      const address = await getAddress.execute(this.franchise.address.zipCode);
      if (address) {
        this.franchise.address.street = address.logradouro;
        this.franchise.address.neighborhood = address.bairro;
        this.franchise.address.cityName = address.localidade || "";
        this.franchise.address.state = address.uf || "";
        this.franchise.address.ibge = address.ibge || "";
      }
    },
    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.handleLoadFranchise();
    }
  },
});
</script>
<style scoped>
.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
@/services/franchises/CreateFranchise@/services/franchises/GetFranchise
