import api from "@/services/api";

import { WithdrawalType } from "@/types/withdrawal/Withdrawal";
class GetListWithdrawal {
  execute = async () => {
    const withdrawals = await api().get(`/transactions/withdrawal/list`);
    return withdrawals.data as WithdrawalType[];
  };
}

export { GetListWithdrawal };
