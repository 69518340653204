import api from "@/services/api";

class PaymentWithdrawal {
  execute = async (transactionId: string) => {
    const withdrawal = await api().put(`/transactions/withdrawal/${transactionId}/confirm`);
    return withdrawal;
  };
}

export { PaymentWithdrawal };
