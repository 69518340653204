import api from "@/services/api";

import { BrbonusListType } from "@/types/brbonus/Brbonus";
class GetAllBrbonus {
  execute = async () => {
    const phases = await api().get(`/phases/brbonus-reports`);
    return phases.data as BrbonusListType[];
  };
}

export { GetAllBrbonus };
