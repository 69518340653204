<template>
  <div>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-data-table
        :header-props="{ sortByText: 'Filtrar por' }"
        :headers="headers"
        :items="franchisees"
        :items-per-page="10"
        :footer-props="{ 'items-per-page-text': 'produtos por página' }"
        :search="search"
        no-data-text="Nenhum produto encontrado"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ item.name }}</td>
              <td class="d-block d-sm-table-cell">{{ item.value }}</td>
              <td class="d-block d-sm-table-cell">{{ item.partner.name }}</td>
              <td class="d-block d-sm-table-cell">{{ item.brpoints }}</td>
              <td align="right" class="d-block d-sm-table-cell">
                <v-icon size="24" color="#e5183d" @click="handleDetailProduct(item.id, item.name)"
                  >mdi-menu</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ProductType } from "@/types/products/Product";
import { GetAllProducts } from "@/services/products/GetAllProducts";

interface SortItem {
  name: string;
  phone: string;
  createdAt: string;
}

export default defineComponent({
  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      showModal: false,
      showModalRemove: false,

      franchisees: [] as ProductType[],
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        { text: "Valor", value: "value" },
        { text: "Parceiro", value: "partner.name" },
        { text: "BRPoints", value: "brpoints" },
        {
          text: "Ações",
          align: "end",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async handleLoadFranchisees() {
      this.loading = true;
      const getAllFranchisees = new GetAllProducts();
      const franchisees = await getAllFranchisees.execute();

      this.franchisees = franchisees;
      this.loading = false;
    },
    handleDetailProduct(id: string, nameProduct: string) {
      this.$router.push({
        name: `productsSignatureView`,
        params: { nameProduct: nameProduct, id: id },
      });
    },
  },
  mounted() {
    this.handleLoadFranchisees();
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
