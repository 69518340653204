import api from "@/services/api";

class PaymentPhases {
  execute = async (date: string) => {
    const result = await api().post(`/distributions/payment`, {
      distributionDate: date,
    });
    return result;
  };
}

export { PaymentPhases };
