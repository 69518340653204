import api from "@/services/api";

import { FranchiseDetailsType } from "@/types/franchise/Franchise";

class CreateFranchise {
  execute = async (franchise: FranchiseDetailsType) => {
    const franchiseSend = await api().post(`/franchise`, {
      name: franchise.name,
      phone: franchise.phone,
      email: franchise.email,
      document: franchise.document,
      pixkey: franchise.pixkey,
      address: {
        street: franchise.address.street,
        state: franchise.address.state,
        addressNumber: franchise.address.addressNumber,
        neighborhood: franchise.address.neighborhood,
        complement: franchise.address.complement,
        zipCode: franchise.address.zipCode,
        cityName: franchise.address.cityName,
        ibge: franchise.address.ibge,
      },
      digitalAccountNumber: franchise.digitalAccountNumber,
      digitalAccountDocument: franchise.document,
    });
    return franchiseSend.data as FranchiseDetailsType;
  };
}

export { CreateFranchise };
