import api from "@/services/api";

class GetTransaction {
  execute = async (
    ibge: Number | undefined,
    startDate: String | undefined,
    endDate: String | undefined
  ) => {
    const transactions = await api().get(`transactions?status=CONFIRMED&type=PAYMENT`, {
      params: {
        ibge: ibge,
        startDate: startDate,
        endDate: endDate,
      },
    });

    return transactions.data;
  };
}

export { GetTransaction };
