import api from "@/services/api";

import { PhaseDetailType } from "@/types/phase/Phases";
class GetPhase {
  execute = async (date: string) => {
    const phase = await api().get(`/distributions/details?date=${date}`);
    return phase.data as PhaseDetailType;
  };
}

export { GetPhase };
