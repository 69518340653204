import { render, staticRenderFns } from "./OnboardingDetailView.vue?vue&type=template&id=bc05b168&scoped=true"
import script from "./OnboardingDetailView.vue?vue&type=script&lang=ts"
export * from "./OnboardingDetailView.vue?vue&type=script&lang=ts"
import style0 from "./OnboardingDetailView.vue?vue&type=style&index=0&id=bc05b168&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc05b168",
  null
  
)

export default component.exports