import api from "@/services/api";

import { ProductType } from "@/types/products/Product";
class GetAllProducts {
  execute = async () => {
    const products = await api().get(`/products`);
    return products.data as ProductType[];
  };
}

export { GetAllProducts };
