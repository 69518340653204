import api from "@/services/api";

class PaymentBrbonus {
  execute = async (date: string) => {
    const result = await api().post(`/phases/brbonus-distribution/payment/${date}`);
    return result;
  };
}

export { PaymentBrbonus };
