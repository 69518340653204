import api from "@/services/api";

import { BrBonusDetailType } from "@/types/brbonus/Brbonus";
class GetBrBonus {
  execute = async (date: string) => {
    const brBonus = await api().get(`/phases/brbonus-distribution?date=${date}`);
    return brBonus.data as BrBonusDetailType;
  };
}

export { GetBrBonus };
