import api from "@/services/api";

import { PhaseSystemUsersType } from "@/types/phase/Phases";

class GetPhasesUsers {
  execute = async (phase: string) => {
    const users = await api().get(`/phases/users/${phase}`);
    return users.data as PhaseSystemUsersType[];
  };
}

export { GetPhasesUsers };
