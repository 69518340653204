<template>
  <div>
    <div class="header">
      <title-vue :title="`${$route.params.nameProduct}`" />
    </div>

    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <div v-else>
      <v-card>
        <v-data-table
          :headers="headers"
          :items-per-page="23"
          :items="signatures"
          :footer-props="{ 'items-per-page-text': 'assinaturas por página' }"
          :options="{ pagination: false }"
          class="elevation-0"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td class="d-block d-sm-table-cell">{{ item.customer.name }}</td>
                <td class="d-block d-sm-table-cell">
                  {{ formatDocument(item.customer.document) }}
                </td>
                <td class="d-block d-sm-table-cell">{{ formatPhone(item.customer.phone) }}</td>
                <td class="d-block d-sm-table-cell">{{ item.customer.email }}</td>
                <td class="d-block d-sm-table-cell">{{ formatDate(item.lastPayment) }}</td>
                <td class="d-block d-sm-table-cell">{{ formatDate(item.dueDate) }}</td>
                <td class="d-block d-sm-table-cell">
                  <v-checkbox
                    v-model="item.isRegistered"
                    @change="handleUpdateRegister(item.id, item.isRegistered)"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TitleVue from "@/components/title/Title.vue";

import { GetProductSignature } from "@/services/products/GetProductSignature";
import { UpdateSignature } from "@/services/products/UpdateSignature";

import { ProductSignature } from "@/types/products/Product";

import { format } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  email: string;
  document: string;
}

export default defineComponent({
  components: { TitleVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      signatures: [] as ProductSignature[],
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      nameProduct: "",
      headers: [
        {
          text: "Consumidor",
          value: "customer.name",
        },
        {
          text: "CPF",
          value: "customer.document",
        },
        {
          text: "Telefone",
          value: "customer.phone",
        },
        {
          text: "Email",
          value: "customer.email",
        },
        { text: "Data do pagamento", value: "lastPayment" },
        { text: "Data de vencimento", value: "dueDate" },
        { text: "Registrado", value: "isRegistered" },
      ],
    };
  },
  methods: {
    async handleLoadProduct() {
      this.loading = true;
      const getProductSignature = new GetProductSignature();
      const signatures = await getProductSignature.execute(this.$route.params.id);
      this.signatures = signatures;
      this.loading = false;
    },
    async handleUpdateRegister(id: string, isRegistered: boolean) {
      const updateSignature = new UpdateSignature();
      const signature = await updateSignature.execute(id, isRegistered);
      if (signature.status === 200) {
        this.$store.dispatch("showSnack", {
          message: "Registro feito com sucesso",
          color: "green darken-1",
        });
      }
      console.log(signature.status);
    },

    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },

  mounted() {
    if (this.$route.params.id) {
      this.handleLoadProduct();
    } else {
      this.$router.back();
    }
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-data-footer {
  display: none !important;
}
</style>
@/services/distribution/GetPhase@/services/distribution/PaymentPhases
