<template>
  <div>
    <div class="header">
      <title-vue title="Dashboard" />
    </div>
    <v-row>
      <v-col cols="4">
        <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="handleFormatDate"
              label="Período"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="selectedRange" range locale="pt-br">
            <v-btn @click="closeDialog" color="primary">Cancelar</v-btn>
            <v-btn @click="handleLoadDashboard" color="primary">Ok</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />

    <div v-else>
      <p class="subtitle-2">Monetário</p>
      <v-row class="d-flex align-center justify-start mb-2">
        <v-col cols="12" md="3">
          <card-resume-dashboard-vue
            backgroundColor="#24A859"
            title1="Faturamento"
            :title2="formatPrice(dashboard.invoicing)"
          />
        </v-col>
        <v-col cols="12" md="3">
          <card-resume-dashboard-vue
            backgroundColor="#24A859"
            title1="Lucro"
            :title2="formatPrice(dashboard.profit)"
          />
        </v-col>
      </v-row>
      <p class="subtitle-2">Pessoas</p>
      <v-row class="d-flex align-center justify-start mb-2">
        <v-col cols="12" md="3">
          <card-resume-dashboard-vue
            backgroundColor="#5A41F3"
            title1="Total geral de consumidores"
            :title2="dashboard.totalCustomers"
          />
        </v-col>
        <v-col cols="12" md="3">
          <card-resume-dashboard-vue
            backgroundColor="#5A41F3"
            title1="Consumidores cadastrados no período"
            :title2="dashboard.customersPerPeriod"
          />
        </v-col>
        <v-col cols="12" md="3">
          <card-resume-dashboard-vue
            backgroundColor="#242968"
            title1="Total geral de parceiros"
            :title2="dashboard.totalPartners"
          />
        </v-col>
        <v-col cols="12" md="3">
          <card-resume-dashboard-vue
            backgroundColor="#242968"
            title1="Parceiros cadastrados no período"
            :title2="dashboard.partnersPerPeriod"
          />
        </v-col>
      </v-row>
      <p class="subtitle-2">Valores</p>
      <v-row class="d-flex align-center justify-start">
        <v-col cols="12" md="2">
          <card-resume-dashboard-vue
            backgroundColor="#E5183D"
            title1="BRP acumulado no período"
            :title2="formatNumber(dashboard.brpointsPerPeriod || 0)"
          />
        </v-col>
        <v-col cols="12" md="2">
          <card-resume-dashboard-vue
            backgroundColor="#E5183D"
            title1="BRBônus acumulado no período"
            :title2="formatNumber(dashboard.brbonusAmountPerPeriod || 0)"
          />
        </v-col>
        <v-col cols="12" md="2">
          <card-resume-dashboard-vue
            backgroundColor="#E5183D"
            title1="BRBônus acumulado no mês"
            :title2="formatNumber(dashboard.currentBrbonusAmount || 0)"
          />
        </v-col>
        <v-col cols="12" md="2">
          <card-resume-dashboard-vue
            backgroundColor="#E5183D"
            title1="Usuários ativos para BRBônus no mês"
            :title2="dashboard.currentActiveBrpointsInBrbonus || 0"
          />
        </v-col>

        <v-col cols="12" md="2">
          <card-resume-dashboard-vue
            backgroundColor="#E5183D"
            title1="Valor do BRP no BRBônus no mês"
            :title2="formatNumber(dashboard.currentBrpointValue || 0)"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TitleVue from "@/components/title/Title.vue";
import CardResumeDashboardVue from "@/components/cardResumeDashboard/CardResumeDashboard.vue";
import { GetDashboard } from "@/services/dashboard/GetDashboard";
import { DashboardType } from "@/types/dashboard/Dashboard";

export default defineComponent({
  components: { TitleVue, CardResumeDashboardVue },
  data() {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const formattedToday = today.toISOString().split("T")[0];
    const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().split("T")[0];

    return {
      dialog: false,
      selectedRange: [formattedThirtyDaysAgo, formattedToday],
      startDate: formattedThirtyDaysAgo,
      endDate: formattedToday,
      mobileBreakpoint: 600,
      loading: true,
      dashboard: {
        brbonusAmountPerPeriod: 0,
        brpointsPerPeriod: 0,
        currentActiveBrpointsInBrbonus: 0,
        currentBrbonusAmount: 0,
        currentBrpointValue: 0,
        customersPerPeriod: 0,
        invoicing: 0,
        partnersPerPeriod: 0,
        profit: 0,
        totalCustomers: 0,
        totalPartners: 0,
      } as DashboardType,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async handleLoadDashboard() {
      this.loading = true;
      const getDashboard = new GetDashboard();
      if (this.selectedRange.length > 0) {
        if (this.selectedRange[0] > this.selectedRange[1]) {
          this.startDate = this.selectedRange[1];
          this.endDate = this.selectedRange[0];
        } else {
          this.startDate = this.selectedRange[0];
          this.endDate = this.selectedRange[1];
        }
      }
      const dashboard = await getDashboard.execute(this.startDate, this.endDate);
      this.dashboard = dashboard;
      this.loading = false;
      this.closeDialog();
    },

    formatPrice(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(value);
      return formattedValue;
    },
    formatNumber(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = value.toFixed(2).replace(".", ",");
      return formattedValue;
    },
  },
  computed: {
    handleFormatDate(): string[] {
      const datesString = this.selectedRange?.map((dates: string | undefined) => {
        if (dates) {
          const [year, month, day] = dates.split("-");
          return ` ${day}/${month}/${year}`;
        }
        return "";
      });
      return datesString || [];
    },
  },
  mounted() {
    this.handleLoadDashboard();
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
