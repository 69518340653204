<template>
  <div>
    <div class="header">
      <title-vue :title="`BRBonus do mês ${formatDate($route.params.date)}`" />
      <status-badge-vue :status="brBonus.status" />
    </div>

    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <div v-else class="header">
      <v-row class="pa-4">
        <v-col cols="2 mr-8">
          <card-resume-vue
            backgroundColor="#5A41F3"
            title1="Total de usuários no dia"
            :title2="String(brBonus.usersQuantity)"
          />
        </v-col>
        <v-col cols="2 mr-8">
          <card-resume-vue
            backgroundColor="#24A859"
            title1="Valor do BRP"
            :title2="formatPrice(brBonus.brpointValue)"
          />
        </v-col>
        <v-col cols="2">
          <card-resume-vue
            backgroundColor="#24A859"
            title1="Valor total"
            :title2="formatPrice(brBonus.totalAmount)"
          />
        </v-col>
      </v-row>
      <v-btn
        :disabled="brBonus.status == 'PAID' || loading"
        :dark="brBonus.status != 'PAID'"
        color="#e5183d"
        @click="openDialog = true"
        >Liberar pagamento</v-btn
      >
    </div>
    <v-dialog v-model="openDialog" width="600" persistent transition="dialog-top-transition">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <h2 class="black--text">
            Deseja realmente liberar o pagamento do BRBonus do mês
            {{ formatDateRef($route.params.date) }}?
          </h2>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn elevation="0" text @click="openDialog = false"> Não </v-btn>
          <v-btn elevation="0" dark color="#e5183d" @click="handlePaymentBrbonus"> Sim </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CardResumeVue from "@/components/cardResume/CardResume.vue";
import TitleVue from "@/components/title/Title.vue";
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";
import { BrBonusDetailType } from "@/types/brbonus/Brbonus";
import { GetBrBonus } from "@/services/brbonus/GetBrbonus";
import { PaymentBrbonus } from "@/services/brbonus/PaymentBrbonus";

import { parseISO, subMonths } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  email: string;
  document: string;
}

export default defineComponent({
  components: { CardResumeVue, TitleVue, StatusBadgeVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      openDialog: false,
      brBonus: {
        totalAmount: 0,
        usersQuantity: 0,
        brpointValue: 0,
      } as BrBonusDetailType,

      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Faixa",
          value: "",
        },
        { text: "Participação BRBonus", value: "rangePercent" },
        { text: "Distribuição por faixa", value: "totalAmount" },
        { text: "Número de participantes", value: "usersQuantity" },
        { text: "Valor por participante ", value: "individualAmount" },
      ],
    };
  },
  methods: {
    async handleLoadBrBonus() {
      this.loading = true;
      const getBrBonus = new GetBrBonus();
      const brBonus = await getBrBonus.execute(this.$route.params.date);
      this.brBonus = brBonus;
      this.loading = false;
    },
    async handlePaymentBrbonus() {
      this.loading = true;
      const paymentBrbonus = new PaymentBrbonus();
      await paymentBrbonus.execute(this.$route.params.date).then((res) => {
        if (res.data.success) {
          this.$store.dispatch("showSnack", {
            message: "BRBonus pago com sucesso",
            color: "green darken-1",
          });
        } else {
          this.$store.dispatch("showSnack", {
            message: "Erro ao pagar o BRBonus",
            color: "red",
          });
        }
      });
      this.handleLoadBrBonus();
      this.openDialog = false;
    },

    handleNewClient() {
      this.$router.push({
        name: "clientForm",
        params: { modeForm: "new" },
      });
    },
    handleDetailClient(id: string) {
      this.$router.push(`/client/form/${id}`);
    },

    formatDate: (date: string) => {
      const parsedDate = parseISO(date);
      const modifiedDate = subMonths(parsedDate, 1);
      const month = modifiedDate.getMonth() + 1; // Adicionar 1 porque os meses são baseados em zero

      const monthString = month === 0 ? "12" : String(month).padStart(2, "0"); // Preencher com zero à esquerda se necessário

      const year = modifiedDate.getFullYear();

      return `${monthString}/${year}`;
    },
    formatDateRef: (date: string): string => {
      const parsedDate = parseISO(date);
      const modifiedDate = subMonths(parsedDate, 1);
      const month = modifiedDate.getMonth() + 1;

      const monthString = month === 0 ? "12" : String(month).padStart(2, "0");

      const year = modifiedDate.getFullYear();

      return `${monthString}/${year}`;
    },
    formatPrice(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 4,
      }).format(value);
      return formattedValue;
    },
  },

  mounted() {
    if (this.$route.params.date) {
      this.handleLoadBrBonus();
    } else {
      this.$router.back();
    }
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-data-footer {
  display: none !important;
}
</style>
