<template>
  <div>
    <div class="header">
      <title-vue title="Solicitações de saque" />
    </div>

    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <div v-else>
      <v-card>
        <v-data-table
          :headers="headers"
          :items-per-page="23"
          :items="withdrawals"
          :footer-props="{ 'items-per-page-text': 'assinaturas por página' }"
          :options="{ pagination: false }"
          class="elevation-0"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td class="d-block d-sm-table-cell">{{ item.customer.name }}</td>
                <td class="d-block d-sm-table-cell">
                  {{ formatDocument(item.customer.document) }}
                </td>
                <td class="d-block d-sm-table-cell">
                  {{ formatPrice(item.value) }}
                </td>

                <td class="d-block d-sm-table-cell">{{ item.customer.pixkey }}</td>
                <td class="d-block d-sm-table-cell">{{ formatDate(item.createdAt) }}</td>
                <td class="d-block d-sm-table-cell">
                  <status-badge-vue :status="item.status || 'PENDING'" />
                </td>
                <td class="d-block d-sm-table-cell">
                  <v-btn
                    elevation="0"
                    dark
                    color="#e5183d"
                    @click="handlePaymentWithdrawal(item.id)"
                    >Pagar</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TitleVue from "@/components/title/Title.vue";
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";

import { GetListWithdrawal } from "@/services/withdrawal/GetListWithdrawal";
import { PaymentWithdrawal } from "@/services/withdrawal/PaymentWithdrawal";

import { WithdrawalType } from "@/types/withdrawal/Withdrawal";

import { format } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  email: string;
  document: string;
}

export default defineComponent({
  components: { TitleVue, StatusBadgeVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      withdrawals: [] as WithdrawalType[],
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      nameProduct: "",
      headers: [
        {
          text: "Consumidor",
          value: "customer.name",
        },
        {
          text: "CPF",
          value: "customer.document",
        },
        {
          text: "Valor",
          value: "value",
        },
        {
          text: "Chave PIX",
          value: "customer.pixkey",
        },
        { text: "Data da solicitação", value: "createdAt" },
        {
          text: "Status",
          value: "status",
        },
        { text: "Pagar", value: "" },
      ],
    };
  },
  methods: {
    async handleLoadWithdrawals() {
      this.loading = true;
      const getWithdrawals = new GetListWithdrawal();
      const withdrawals = await getWithdrawals.execute();
      this.withdrawals = withdrawals;
      this.loading = false;
    },
    async handlePaymentWithdrawal(id: string) {
      const paymentWithdrawal = new PaymentWithdrawal();
      const payment = await paymentWithdrawal.execute(id);
      if (payment.status === 200) {
        this.$store.dispatch("showSnack", {
          message: "Pagamento feito com sucesso",
          color: "green darken-1",
        });
      }
      this.handleLoadWithdrawals();
    },
    formatPrice(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(value);
      return formattedValue;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },

  mounted() {
    this.handleLoadWithdrawals();
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-data-footer {
  display: none !important;
}
</style>
