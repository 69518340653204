<template>
  <v-container>
    <v-template>
      <div class="header">
        <title-vue :title="title" />
      </div>

      <v-row class="mt-2">
        <v-col cols="12">
          <v-form ref="form" v-model="valid" @submit.prevent>
            <v-card>
              <v-card-text>
                <p class="subtitle-2">Dados do franqueado</p>
                <v-row>
                  <v-col cols="12" md="6">
                    <input-text-vue
                      label="Nome *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchisee.name"
                    />
                  </v-col>

                  <v-col cols="12" md="3">
                    <input-text-vue
                      label="CPF *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchisee.document"
                      v-mask="'###.###.###-##'"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <input-text-vue
                      label="Telefone *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchisee.phone"
                      v-mask="'(##) #####-####'"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <input-text-vue
                      label="Email *"
                      :required="true"
                      :disabled="loading"
                      :readonly="readonly"
                      :rules="rules"
                      v-model="franchisee.email"
                    />
                  </v-col>
                  <v-col class="discipline" cols="12" md="6">
                    <v-autocomplete
                      :disabled="loading"
                      :items="franchises"
                      v-model="franchisee.franchises"
                      :filter="customDisciplineFilter"
                      multiple
                      item-text="cityName"
                      item-value="id"
                      label="Franquias"
                      no-data-text="Nenhuma franquia encontrada"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row v-if="!franchisee.id && !loading">
                  <v-spacer></v-spacer>
                  <v-col class="d-flex justify-end">
                    <button-vue dark class="mr-4" type="cancel" @click="handleCancel"
                      >Cancelar</button-vue
                    >
                    <button-vue
                      dark
                      :type="!valid ? 'default' : 'primary'"
                      @click="handleSave"
                      :disabled="!valid || loading"
                      >Salvar</button-vue
                    >
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col class="d-flex justify-start">
                    <button-vue dark class="mr-4" type="cancel" @click="handleCancel"
                      >Voltar</button-vue
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ButtonVue from "@/components/button/Button.vue";
import TitleVue from "@/components/title/Title.vue";
import InputTextVue from "@/components/inputs/InputText.vue";

import { CreateFranchisee } from "@/services/franchisees/CreateFranchisee";
import { GetAllFranchisesAvailable } from "@/services/franchises/GetAllFranchisesAvailable";

import { format } from "date-fns";
import { FranchiseeDetailsType } from "@/types/franchisee/Franchisee";
import { FranchiseType } from "@/types/franchise/Franchise";

export default defineComponent({
  components: { ButtonVue, TitleVue, InputTextVue },
  data() {
    return {
      loading: false,
      valid: null,
      title: "Novo franqueado",
      readonly: false,
      franchisee: {
        id: "",
        name: "",
        phone: "",
        email: "",
        document: "",
        createdAt: "",
      } as FranchiseeDetailsType,
      rules: [(v: string) => !!v || "Este campo é obrigatório."],
      franchises: [] as FranchiseType[],
    };
  },
  methods: {
    async handleLoadFranchises() {
      this.loading = true;
      const getAllFranchisesAvailable = new GetAllFranchisesAvailable();
      const franchises = await getAllFranchisesAvailable.execute();
      this.franchises = franchises;
      this.loading = false;
    },
    async handleSave() {
      this.loading = true;
      const createFranchisee = new CreateFranchisee();
      await createFranchisee
        .execute(this.franchisee)
        .then(() => {
          this.$store.dispatch("showSnack", {
            message: "Franqueado cadastrado com sucesso",
            color: "green darken-1",
          });
          this.$router.push("/franchisees");
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao cadastrar o franqueado",
            color: "red",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.$router.push("/franchisees");
    },
    customDisciplineFilter(item: FranchiseType, queryText: string) {
      const textOne = item.cityName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },
  mounted() {
    this.handleLoadFranchises();
  },
});
</script>
<style scoped>
.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
