<template>
  <v-container fluid class="mt-10">
    <div class="header">
      <v-row>
        <v-col cols="12" md="4">
          <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="handleFormatDate"
                label="Período de vendas"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="selectedRange" range locale="pt-br">
              <v-spacer></v-spacer>
              <v-btn @click="closeDialog" color="primary">Cancelar</v-btn>
              <v-btn @click="dialog = !dialog" color="primary">Ok</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            clearable
            :items="franchises"
            v-model="franchiseFiltered"
            item-text="cityName"
            item-value="ibge"
            label="Franquia"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2" align-self="center">
          <v-btn block @click="handleGetTransactions" elevation="0" color="#e5183d" dark
            >Buscar</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <v-col cols="12">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquise pelo nome, CPF, email ou telefone"
      />
    </v-col>
    <v-data-table
      :header-props="{ sortByText: 'Filtrar por' }"
      :headers="headers"
      no-data-text="Nenhuma transação encontrada"
      :footer-props="{ 'items-per-page-text': 'transações por página' }"
      :items-per-page="10"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="transactions"
      :search="search"
      :loading="loading"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="d-block d-sm-table-cell">{{ formatDate(item.createdAt || "") }}</td>
            <td class="d-block d-sm-table-cell">{{ item.partner?.name || "" }}</td>
            <td class="d-block d-sm-table-cell">{{ item.customer?.name || "" }}</td>
            <td class="d-block d-sm-table-cell">
              {{ formatDocument(item.customer?.document || "") }}
            </td>
            <td class="d-block d-sm-table-cell">
              {{ item.partner?.cityName || "" }} - {{ item.partner?.state || "" }}
            </td>
            <td class="d-block d-sm-table-cell">{{ formatPrice(item.value || 0) }}</td>
            <td class="d-block d-sm-table-cell">{{ item.brpointsValue || "" }}</td>
            <td class="d-block d-sm-table-cell">{{ item.brbonusvalue || "" }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { format, parseISO } from "date-fns";

import { FranchiseType } from "@/types/franchise/Franchise";
import { Transactions } from "@/types/transaction/Transaction";

import { GetAllFranchises } from "@/services/franchises/GetAllFranchises";
import { GetTransaction } from "@/services/transactions/GetTransactions";

export default defineComponent({
  data() {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const formattedToday = today.toISOString().split("T")[0];
    const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().split("T")[0];
    return {
      headers: [
        { text: "Data da venda", value: "createdAt" },
        { text: "Parceiro", value: "partner.name" },
        { text: "Consumidor", value: "customer.name" },
        { text: "CPF", value: "customer.document" },
        { text: "Cidade", value: "partner.cityName" },
        { text: "Valor", value: "value" },
        { text: "BRP", value: "brpointsValue" },
      ],
      sortBy: "createdAt",
      sortDesc: false,
      search: "",
      transactions: [] as Transactions[],
      dialog: false,
      selectedRange: [formattedThirtyDaysAgo, formattedToday],
      startDate: formattedThirtyDaysAgo,
      endDate: formattedToday,
      loading: false,
      franchises: [] as FranchiseType[],
      franchiseFiltered: undefined,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async handleGetTransactions() {
      this.loading = true;
      const getTransaction = new GetTransaction();
      if (this.selectedRange.length > 0) {
        if (this.selectedRange[0] > this.selectedRange[1]) {
          this.startDate = this.selectedRange[1];
          this.endDate = this.selectedRange[0];
        } else {
          this.startDate = this.selectedRange[0];
          this.endDate = this.selectedRange[1];
        }
      }

      const allTransactions = await getTransaction.execute(
        this.franchiseFiltered,
        this.startDate,
        this.endDate
      );
      this.transactions = allTransactions;
      this.transactions = this.transactions.filter(
        (transaction: Transactions) => transaction.type == "PAYMENT"
      );
      this.loading = false;
    },
    async handleLoadFranchises() {
      this.loading = true;
      const getAllFranchises = new GetAllFranchises();
      const franchises = await getAllFranchises.execute();
      this.franchises = franchises;
      this.loading = false;
    },

    formatDate: (date: string): string => {
      const parsedDate = parseISO(date);
      parsedDate.setUTCHours(parsedDate.getUTCHours());
      const formattedDate = format(parsedDate, "dd/MM/yyyy HH:mm:ss");
      return formattedDate;
    },

    formatPrice(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(value);
      return formattedValue;
    },
    handleTranslateTypePtBr(type: string) {
      switch (type) {
        case "WITHDRAWAL":
          return "Saque";
        case "PAYMENT":
          return "Pagamento";

        default:
          return type.toLocaleUpperCase();
      }
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },
  computed: {
    handleFormatDate(): string[] {
      const datesString = this.selectedRange?.map((dates: string | undefined) => {
        if (dates) {
          const [year, month, day] = dates.split("-");
          return ` ${day}/${month}/${year}`;
        }
        return "";
      });
      return datesString || [];
    },
  },
  mounted() {
    this.handleLoadFranchises();
    this.handleGetTransactions();
  },
});
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
