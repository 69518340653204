import api from "@/services/api";

import { FranchiseeType } from "@/types/franchisee/Franchisee";

class GetAllFranchisees {
  execute = async () => {
    const franchisees = await api().get(`/franchise-users`);
    return franchisees.data.franchiseUsers as FranchiseeType[];
  };
}

export { GetAllFranchisees };
