<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent transition="dialog-top-transition">
      <v-form ref="form" v-model="valid" @submit.prevent>
        <v-card>
          <v-card-title>
            <title-vue title="Nova categoria" />
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="9">
                  <v-text-field
                    label="Descrição *"
                    required
                    :disabled="loading"
                    v-model="category.name"
                    :rules="category.nameRules"
                  />
                </v-col>
              </v-row>
            </v-container>
            <small>* Indica campo obrigatório</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeModal"> Cancelar </v-btn>
            <v-btn
              type="submit"
              color="primary"
              variant="text"
              @click="handleSave"
              :disabled="!valid || loading"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { CreateCategory } from "@/services/categories/CreateCategory";
import TitleVue from "@/components/title/Title.vue";
export default {
  props: ["showModal", "closeModal"],
  components: { TitleVue },
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    category: {
      name: "",
      nameRules: [
        (value) => {
          if (value) return true;
          return "O campo descrição é obrigatório.";
        },
      ],
    },
  }),
  methods: {
    handleCancel() {
      this.category = {
        name: "",
      };
      this.closeModal();
    },
    async handleSave() {
      this.loading = true;
      const createCategory = new CreateCategory();
      const category = await createCategory.execute(this.category);
      if (category) {
        this.$store.dispatch("showSnack", {
          message: "Categoria cadastrada com sucesso",
          color: "green darken-1",
        });
        this.category = {
          name: "",
        };
        this.closeModal();
      } else {
        this.$store.dispatch("showSnack", {
          message: "Erro ao cadastrar a categoria",
          color: "red",
        });
      }
      this.loading = false;
    },
  },
  created() {
    this.dialog = this.showModal;

  },
};
</script>

<style></style>
