<template>
  <div>
    <div class="header">
      <title-vue title="Sistema de fases ao vivo" />
    </div>
    <v-row class="pa-4 d-none d-md-flex d-md-none">
      <v-col cols="12" md="2">
        <card-resume-vue
          backgroundColor="#5A41F3"
          title1="Total de usuários ativos"
          :title2="totalUsers"
        />
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-data-table
        :headers="headers"
        :items="phases"
        :footer-props="{ 'items-per-page-text': 'fases por página' }"
        :search="search"
        no-data-text="Nenhum fase encontrada"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">Fase {{ item.phase }}</td>
              <td align="left" class="d-block d-sm-table-cell">
                {{ item.totalUsers }}
              </td>
              <td align="right" class="d-block d-sm-table-cell">
                <v-icon size="24" color="#e5183d" @click="handleDetailPhaseUsers(item.phase)"
                  >mdi-menu</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TitleVue from "@/components/title/Title.vue";
import CardResumeVue from "@/components/cardResume/CardResume.vue";
import { PhaseSystemLiveType } from "@/types/phase/Phases";

import { GetPhasesLive } from "@/services/phases/GetPhasesLive";

interface SortItem {
  name: string;
  phone: string;
  createdAt: string;
}

export default defineComponent({
  components: { TitleVue, CardResumeVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      showModal: false,
      showModalRemove: false,
      phases: [] as PhaseSystemLiveType[],
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      totalUsers: 0,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Fase",
          value: "phase",
        },
        { text: "Total de participantes", value: "totalUsers", align: "left" },
        {
          text: "Ações",
          align: "end",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async handleLoadPhases() {
      this.loading = true;
      const getPhasesLive = new GetPhasesLive();
      const phases = await getPhasesLive.execute();
      this.phases = phases;
      this.totalUsers = phases.reduce((accumulator, current) => {
        return accumulator + current.totalUsers;
      }, 0);
      this.loading = false;
    },
    handleDetailPhaseUsers(phase: string) {
      this.$router.push(`/phases/live/${phase}`);
    },
  },
  mounted() {
    this.handleLoadPhases();
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
@/services/distribution/GetPhasesLive
