import api from "@/services/api";
import { FranchiseDetailsType } from "@/types/franchise/Franchise";

class GetFranchise {
  execute = async (id: string) => {
    const franchise = await api().get(`/franchise/${id}`);
    return franchise.data as FranchiseDetailsType;
  };
}

export { GetFranchise };
