<template>
  <div>
    <div class="header">
      <title-vue title="Lista de fases" />
      <v-btn dark color="#e5183d" @click="handlePhasesSystemLive()"
        >Sistema de fases - Ao vivo</v-btn
      >
    </div>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquise por data ou status"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="phases"
        :footer-props="{ 'items-per-page-text': 'fases por página' }"
        :search="search"
        no-data-text="Nenhum fase encontrada"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ formatDate(item.date) }}</td>
              <td class="d-block d-sm-table-cell">{{ item.usersQuantity }}</td>
              <td class="d-block d-sm-table-cell">{{ formatPrice(item.totalAmount) }}</td>
              <td align="right" class="d-block d-sm-table-cell">
                <status-badge-vue :status="item.status" />
              </td>
              <td align="right" class="d-block d-sm-table-cell">
                <v-icon size="24" color="#e5183d" @click="handleDetailPhase(item.date)"
                  >mdi-menu</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TitleVue from "@/components/title/Title.vue";
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";

import { PhasesListType } from "@/types/phase/Phases";

import { GetAllPhases } from "@/services/phases/GetAllPhases";

import { format, parseISO } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  createdAt: string;
}

export default defineComponent({
  components: { TitleVue, StatusBadgeVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      showModal: false,
      showModalRemove: false,
      phases: [] as PhasesListType[],
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Data de movimentação",
          value: "date",
        },
        { text: "Total de usuários", value: "usersQuantity" },
        { text: "Total distribuído", value: "totalAmount" },
        { text: "Status", align: "right", value: "status" },

        {
          text: "Ações",
          align: "end",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async handleLoadPhases() {
      this.loading = true;
      const getAllPhases = new GetAllPhases();
      const phases = await getAllPhases.execute();
      this.phases = phases;
      this.loading = false;
    },
    handleDetailPhase(date: string) {
      this.$router.push(`/phase-distribution/${date}`);
    },
    handlePhasesSystemLive() {
      this.$router.push(`/phases/live`);
    },
    formatPrice(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(value);
      return formattedValue;
    },
    phoneNumberFormat: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(parseISO(date), "dd/MM/yyyy");
      return dateFormated;
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },
  mounted() {
    this.handleLoadPhases();
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
