<template>
  <div>
    <div class="header">
      <title-vue :title="`Sistema de fases ao vivo - fase ${$route.params.phase}`" />
    </div>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquise pelo nome, CPF, email ou telefone"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :header-props="{ sortByText: 'Filtrar por' }"
        :headers="headers"
        :items="customers"
        :items-per-page="10"
        :footer-props="{
          'items-per-page-text': 'consumidores por página',
        }"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :search="search"
        no-data-text="Nenhum consumidor encontrado"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ item.name }}</td>
              <td class="d-block d-sm-table-cell">{{ formatPrice(item.balance.accumulated) }}</td>
              <td class="d-block d-sm-table-cell">{{ formatPrice(item.balance.current) }}</td>
              <td class="d-block d-sm-table-cell">{{ item.balance.brpoints }}</td>
              <td class="d-block d-sm-table-cell">{{ item.city }}</td>
              <td class="d-block d-sm-table-cell">{{ item.phase }}</td>
              <td class="d-block d-sm-table-cell">{{ item.position }}</td>
              <td class="d-block d-sm-table-cell">
                <v-icon size="24" color="#e5183d" @click="handleDetailCustomer(item.id)"
                  >mdi-menu</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TitleVue from "@/components/title/Title.vue";
import { PhaseSystemUsersType } from "@/types/phase/Phases";

import { GetPhasesUsers } from "@/services/phases/GetPhasesUsers";
import { format } from "date-fns";

export default defineComponent({
  components: { TitleVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      showModal: false,
      showModalRemove: false,
      modeForm: "",
      customers: [] as PhaseSystemUsersType[],

      search: "",
      sortBy: "position",
      sortDesc: false,
      statusFiltered: "ACTIVE",
      franchiseFiltered: undefined,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        { text: "Saldo atual", value: "document" },
        { text: "Saldo acumulado", value: "phone" },
        { text: "BRP", value: "brpoints" },
        { text: "Cidade", value: "city" },
        { text: "Fase", value: "phase" },
        { text: "Posição", value: "position" },
        {
          text: "Ações",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async handleLoadCustomers() {
      this.loading = true;
      const getPhasesUsers = new GetPhasesUsers();
      const customers = await getPhasesUsers.execute(this.$route.params.phase);
      this.customers = customers;
      this.loading = false;
    },
    handleDetailCustomer(id: string) {
      this.$router.push(`/customer/detail/${id}`);
    },
    phoneNumberFormat: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatPrice(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(value);
      return formattedValue;
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },
  mounted() {
    if (this.$route.params.phase) {
      this.handleLoadCustomers();
    } else {
      this.$router.back();
    }
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
@/services/distribution/GetPhasesUsers
