import api from "@/services/api";

import { CategoryType } from "@/types/category/Category";

class CreateCategory {
  execute = async (category: CategoryType) => {
    const categorySend = await api().post(`/partners/new-category`, {
      name: category.name,
    });
    return categorySend.data as CategoryType;
  };
}

export { CreateCategory };
