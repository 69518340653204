<template>
  <v-app>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card>
            <v-list-item>
              <v-list-item-content class="headline text-center">
                <v-list-item-avatar>
                  <v-img contain :src="logo" alt="LOGO BRP"></v-img>
                </v-list-item-avatar>

                <v-list-item-subtitle> Backoffice </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-form ref="form" v-model="valid" @submit.prevent="login">
                <v-text-field
                  v-model="user.document"
                  label="CPF"
                  outlined
                  v-mask="'###.###.###-##'"
                  :rules="rules"
                ></v-text-field>

                <v-text-field
                  v-model="user.password"
                  label="Senha"
                  outlined
                  append-icon="mdi-eye"
                  :rules="rules"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>



                <v-btn
                  type="submit"
                  :disabled="!valid"
                  :dark="valid"
                  color="#ee2c4f"
                  class="mt-5"
                  block
                >
                  Entrar
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Authenticate } from "@/services/user/Authenticate";
import { CredentialType } from "@/types/user/Credential";
import { TokenType } from "@/types/user/Token";
export default defineComponent({
  data() {
    return {
      valid: false,
      user: {
        document: "",
        password: "",
      } as CredentialType,
      rules: [
        (value: string) => {
          if (value) return true;
          return "O campo é obrigatório";
        },
      ],
      showPassword: false,
      logo: require("../../assets/logo-login.png"),
    };
  },
  methods: {
    async login() {
      const authenticate = new Authenticate();
      await authenticate
        .execute(this.user)
        .then((res) => {
          const tokens = {
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
          } as TokenType;
          const tokensString = JSON.stringify(tokens);
          localStorage.setItem("tokens", tokensString);
          this.$router.push("/");
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao efetuar o login",
            color: "red",
          });
        });
    },
    forgotPassword() {
      // Lógica de redefinição de senha aqui
      console.log("Esqueci minha senha");
    },
  },
});
</script>

<style scoped>
.v-application {
  background: #e5183d;
  height: 100vh;
}

.v-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.v-card-title {
  background-color: #3f51b5;
  color: white;
}

.v-card-text {
  padding: 24px;
}

.text-center {
  text-align: center;
}
</style>
