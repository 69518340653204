import Vue from "vue";
import VueRouter, { NavigationGuard } from "vue-router";
import HomeView from "../views/home/HomeView.vue";
import OnboardingView from "../views/onboarding/OnboardingView.vue";
import OnboardingDetailView from "../views/onboarding/OnboardingDetailView.vue";
import FranchisesView from "@/views/franchises/FranchisesView.vue";
import FranchiseFormView from "@/views/franchises/FranchiseFormView.vue";
import FranchiseesView from "@/views/franchisees/FranchiseesView.vue";
import FranchiseeFormView from "@/views/franchisees/FranchiseeFormView.vue";
import Transactions from "@/views/transactions/Transactions.vue";
import PhasesView from "@/views/phases/PhasesView.vue";
import PhasesDistributionView from "@/views/phases/PhasesDistributionView.vue";
import Login from "../views/login/Login.vue";
import BRBonusDistributionViewVue from "@/views/brbonus/BRBonusDistributionView.vue";
import CategoriesView from "@/views/categories/CategoriesView.vue";
import CustomersView from "@/views/customers/CustomersView.vue";
import CustomerDetailView from "@/views/customers/CustomerDetailView.vue";
import PartnersView from "@/views/partners/PartnersView.vue";
import PartnerDetailView from "@/views/partners/PartnerDetailView.vue";
import PartnerQRCODE from "@/views/partners/PartnerQRCODE.vue";
import PhasesLiveView from "@/views/phases/PhasesLiveView.vue";
import PhasesUsersView from "@/views/phases/PhasesUsersView.vue";
import OnboardingPartner from "@/views/onboarding-partner/OnboardingPartner.vue";
import OnboardingDetails from "@/views/onboarding-partner/OnboardingDetailsPartner.vue";
import BrbonusViewVue from "@/views/brbonus/BrbonusView.vue";
import ProductsViewVue from "@/views/products/ProductsView.vue";
import ProductsSignatureViewVue from "@/views/products/ProductsSignatureView.vue";
import WithdrawalViewVue from "@/views/withdrawal/Withdrawal.vue";

Vue.use(VueRouter);

const requireAuth: NavigationGuard = (to, from, next) => {
  const userData = JSON.parse(String(localStorage.getItem("tokens")));
  let token = false;
  if (userData) {
    token = userData.accessToken;
  }
  if (token && to.path === "/login") {
    next("/");
  } else if (token) {
    next();
  } else {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: OnboardingView,
    beforeEnter: requireAuth,
  },
  {
    path: "/onboarding/detail/:id",
    name: "onboardingDetailView",
    component: OnboardingDetailView,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: "/franchises",
    name: "franchises",
    component: FranchisesView,
    beforeEnter: requireAuth,
  },
  {
    path: "/franchise/form/",
    name: "franchiseForm",
    component: FranchiseFormView,
    beforeEnter: requireAuth,
  },
  {
    path: "/franchise/form/:id",
    name: "franchiseDetail",
    component: FranchiseFormView,
    beforeEnter: requireAuth,
  },
  {
    path: "/franchisees",
    name: "franchisees",
    component: FranchiseesView,
    beforeEnter: requireAuth,
  },
  {
    path: "/franchisee/form/",
    name: "franchiseeForm",
    component: FranchiseeFormView,
    beforeEnter: requireAuth,
  },
  {
    path: "/franchisee/form/:id",
    name: "franchiseeDetail",
    component: FranchiseeFormView,
    beforeEnter: requireAuth,
  },
  {
    path: "/transactions",
    name: "trasactions",
    component: Transactions,
    beforeEnter: requireAuth,
  },
  {
    path: "/phases",
    name: "phases",
    component: PhasesView,
    beforeEnter: requireAuth,
  },
  {
    path: "/phase-distribution/:date",
    name: "phaseDistributionView",
    component: PhasesDistributionView,
    beforeEnter: requireAuth,
  },
  {
    path: "/brbonus",
    name: "brbonus",
    component: BrbonusViewVue,
    beforeEnter: requireAuth,
  },
  {
    path: "/brbonus-distribution/:date",
    name: "brBonusDistributionView",
    component: BRBonusDistributionViewVue,
    beforeEnter: requireAuth,
  },
  {
    path: "/phases/live",
    name: "phasesLiveView",
    component: PhasesLiveView,
    beforeEnter: requireAuth,
  },
  {
    path: "/phases/live/:phase",
    name: "phasesUsersView",
    component: PhasesUsersView,
    beforeEnter: requireAuth,
  },
  {
    path: "/categories",
    name: "categoriesView",
    component: CategoriesView,
    beforeEnter: requireAuth,
  },
  {
    path: "/customers",
    name: "customersView",
    component: CustomersView,
    beforeEnter: requireAuth,
  },
  {
    path: "/customers",
    name: "customersView",
    component: CustomersView,
    beforeEnter: requireAuth,
  },
  {
    path: "/customer/detail/:id",
    name: "customerDetailView",
    component: CustomerDetailView,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: "/partners",
    name: "partnersView",
    component: PartnersView,
    beforeEnter: requireAuth,
  },
  {
    path: "/partner/:id",
    name: "partnerDetailView",
    component: PartnerDetailView,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: "/partnerQRCODE/:doc/:name/:id",
    name: "partnerDetailQRCODE",
    component: PartnerQRCODE,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: "/onboarding-partner",
    name: "onboardingPartner",
    component: OnboardingPartner,
    beforeEnter: requireAuth,
  },
  {
    path: "/onboarding-partner/detail/:id",
    name: "onboardingPartnerDetail",
    component: OnboardingDetails,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: "/products",
    name: "products",
    component: ProductsViewVue,
    beforeEnter: requireAuth,
  },
  {
    path: "/products/:id/signatures",
    name: "productsSignatureView",
    component: ProductsSignatureViewVue,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: WithdrawalViewVue,
    beforeEnter: requireAuth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
