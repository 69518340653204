import api from "@/services/api";

import { PartnerType } from "@/types/partner/Partner";

class GetAllPartners {
  execute = async (status: string, ibge?: number) => {
    const partners = await api().get(`/partners`, {
      params: {
        status: status,
        ibge: ibge,
      },
    });
    return partners.data.partners as PartnerType[];
  };
}

export { GetAllPartners };
