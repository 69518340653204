import api from "@/services/api";


class GetAllPartnerOnboarding {
    execute = async (status: string, ibge?: number) => {
        const partnerOnboarding = await api().get('/partners', {
            params: {
                status,
                ibge
            }
        })

        return partnerOnboarding.data
    }
    
}

export { GetAllPartnerOnboarding }