import api from "@/services/api";

class UpdateSignature {
  execute = async (id: String, isRegistered: boolean) => {
    const signature = await api().patch(`/products/signature/${id}`, {
      isRegistered: isRegistered,
    });
    return signature;
  };
}

export { UpdateSignature };
