import api from "@/services/api";

import { FranchiseType } from "@/types/franchise/Franchise";

class GetAllFranchisesAvailable {
  execute = async () => {
    const franchises = await api().get(`/franchises`, {
      params: {
        available: true,
      },
    });

    return franchises.data.franchises as FranchiseType[];
  };
}

export { GetAllFranchisesAvailable };
