<template>
  <div>
    <div class="header">
      <title-vue title="Detalhes do Onboarding" />
      <status-badge-vue :status="status" />
    </div>

    <v-row class="mt-2">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <p class="subtitle-2">Dados pessoais</p>
            <v-row>
              <v-col cols="6">
                <title-subtitle-vue label="Nome completo" :value="name" />
              </v-col>
              <v-col cols="2">
                <title-subtitle-vue label="Data de nascimento" :value="birthdate" />
              </v-col>
              <v-col cols="2">
                <title-subtitle-vue label="Data da solicitação" :value="formatDate(createdAt)" />
              </v-col>
            </v-row>
            <p class="subtitle-2 mt-4">Dados de contato</p>
            <v-row>
              <v-col cols="4">
                <title-subtitle-vue label="Email" :value="email" />
              </v-col>
              <v-col cols="2" class="d-flex align-center">
                <v-row>
                  <v-col cols="10"
                    ><title-subtitle-vue label="Telefone" :value="formatPhone(phone)"
                  /></v-col>
                  <v-col cols="2"
                    ><v-btn class="whatsapp-button" @click="openWhatsApp">
                      Whatsapp <v-icon>mdi-whatsapp</v-icon>
                    </v-btn></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
            <p class="subtitle-2 mt-4">Dados de endereço</p>
            <v-row>
              <v-col cols="4">
                <title-subtitle-vue label="Logradouro" :value="street" />
              </v-col>
              <v-col cols="2">
                <title-subtitle-vue label="Número" :value="addressNumber" />
              </v-col>
              <v-col cols="3">
                <title-subtitle-vue label="Bairro" :value="neighborhood" />
              </v-col>
              <v-col cols="3">
                <title-subtitle-vue label="Complemento" :value="complement" />
              </v-col>
              <v-col cols="4">
                <title-subtitle-vue label="CEP" :value="formatZipCod(zipCode)" />
              </v-col>
              <v-col cols="4">
                <title-subtitle-vue label="Cidade" :value="cityName" />
              </v-col>
              <v-col cols="4">
                <title-subtitle-vue label="Estado" :value="state" />
              </v-col>
            </v-row>
            <p class="subtitle-2 mt-4">Documentos</p>
            <v-row>
              <v-col cols="12">
                <title-subtitle-vue label="CPF" :value="formatDocument(document)" />
              </v-col>
              <v-col cols="4">
                <p class="label">Selfie</p>
                <v-img
                  @click="handleImageClick(selfImage)"
                  width="250"
                  height="250"
                  :src="selfImage"
                  alt="Selfie"
                  aspect-ratio="2.5"
                  class="img-document"
                ></v-img>
              </v-col>

              <v-col cols="4">
                <p class="label">Documento frente</p>
                <v-img
                  width="250"
                  height="200"
                  @click="handleImageClick(frontDocumentImage)"
                  :src="frontDocumentImage"
                  alt="Descrição da imagem"
                  aspect-ratio="2.5"
                  class="img-document"
                ></v-img>
              </v-col>
              <v-col cols="4">
                <p class="label">Documento verso</p>
                <v-img
                  width="250"
                  height="200"
                  @click="handleImageClick(backDocumentImage)"
                  :src="backDocumentImage"
                  alt="Descrição da imagem"
                  aspect-ratio="2.5"
                  class="img-document"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-end">
        <button-vue dark color="red" class="mr-4" type="repprove" @click="openDialogReprove = true"
          >Reprovar</button-vue
        >
        <button-vue dark color="green" type="primary" @click="handleAprove">Aprovar</button-vue>
      </v-col>
    </v-row>
    <v-dialog v-model="showImageDialog">
      <v-card>
        <v-img :src="clickedImage" contain></v-img>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openDialogReprove" width="600" persistent transition="dialog-top-transition">
      <v-card>
        <v-card-title>Qual o motivo da reprovação?</v-card-title>
        <v-card-text>
          <v-radio-group v-model="reasonReprove">
            <v-radio
              label="Falha em uma das imagens enviadas"
              value="Falha em uma das imagens enviadas"
            ></v-radio>
            <v-radio
              label="Informações não condizem com o documento enviado"
              value="Informações não condizem com o documento enviado"
            ></v-radio>
            <v-radio label="Outro" value="Outro"></v-radio>
            <v-textarea
              v-if="reasonReprove == 'Outro'"
              class="mt-4"
              outlined
              label="Descreva o motivo da reprovação"
              v-model="otherReason"
            ></v-textarea>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn elevation="0" text @click="openDialogReprove = false"> Cancelar </v-btn>
          <v-btn
            elevation="0"
            dark
            color="#e5183d"
            @click="handleReprove"
            :disabled="
              reasonReprove.length <= 0 || (reasonReprove == 'Outro' && otherReason.length <= 0)
            "
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ButtonVue from "@/components/button/Button.vue";
import TitleVue from "@/components/title/Title.vue";
import TitleSubtitleVue from "@/components/titleSubtitle/TitleSubtitle.vue";
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";
import { GetOnboarding } from "@/services/onboardings/GetOnboarding";
import { UpdateOnboarding } from "@/services/onboardings/UpdateOnboarding";
import { format } from "date-fns";

export default defineComponent({
  components: { ButtonVue, TitleVue, TitleSubtitleVue, StatusBadgeVue },
  props: ["mode", "pClient"],
  data() {
    return {
      showImageDialog: false,
      clickedImage: "",
      loading: false,
      id: "",
      name: "",
      birthdate: "",
      status: "",
      createdAt: "",
      openDialogReprove: false,
      phone: "",
      email: "",

      street: "", //
      addressNumber: "", //
      neighborhood: "", //
      complement: "", //
      zipCode: "", //
      cityName: "", //
      state: "", //

      document: "",
      frontDocumentImage: "",
      backDocumentImage: "",
      selfImage: "",
      updatedAt: "",
      reasonReprove: "",
      otherReason: "",
    };
  },
  methods: {
    handleImageClick(imageUrl: string) {
      this.clickedImage = imageUrl;
      this.showImageDialog = true;
    },
    async handleLoadOnboarding() {
      const getOnboarding = new GetOnboarding();
      const onboarding = await getOnboarding.execute(this.$route.params.id);

      this.id = onboarding.id || "";
      this.name = onboarding.name || "";
      this.birthdate = onboarding.birthdate || "";
      this.status = onboarding.status || "";

      this.createdAt = onboarding.createdAt || "";

      this.phone = onboarding.phone || "";
      this.email = onboarding.email || "";

      this.street = onboarding.address.street || "";
      this.addressNumber = onboarding.address.addressNumber || "";
      this.neighborhood = onboarding.address.neighborhood || "";
      this.complement = onboarding.address.complement || "";
      this.zipCode = onboarding.address.zipCode || "";
      this.cityName = onboarding.address.cityName || "";
      this.state = onboarding.address.state || "";

      this.document = onboarding.document || "";
      this.backDocumentImage = onboarding.backDocumentImage || "";
      this.frontDocumentImage = onboarding.frontDocumentImage || "";
      this.selfImage = onboarding.selfImage || "";
    },
    async handleAprove() {
      const updateOnboarding = new UpdateOnboarding();
      updateOnboarding
        .execute(this.$route.params.id, "ACTIVE")
        .then(() => {
          this.$store.dispatch("showSnack", {
            message: "Conta aprovada com sucesso",
            color: "green darken-1",
          });
          this.status = "ACTIVE";
          this.$router.push("/onboarding");
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao aprovar a conta",
            color: "red",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleReprove() {
      const updateOnboarding = new UpdateOnboarding();
      const reason = this.reasonReprove == "Outro" ? this.otherReason : this.reasonReprove;
      updateOnboarding
        .execute(this.$route.params.id, "PENDING", reason)
        .then(() => {
          this.$store.dispatch("showSnack", {
            message: "Conta reprovada com sucesso",
            color: "green darken-1",
          });
          this.status = "PENDING";
          this.openDialogReprove = false;
          this.$router.push("/onboarding");
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao reprovar a conta",
            color: "red",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.$router.push("/onboarding");
    },
    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    openWhatsApp() {
      const phoneNumber = this.phone.replace(/\D/g, "");
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=Olá ${this.name}! Tudo bem? Somos da BRPoints.`;
      window.open(whatsappUrl, "_blank");
    },
    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },
  mounted() {
    this.handleLoadOnboarding();
  },
});
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}

.img-document {
  cursor: zoom-in;
}

.whatsapp-button {
  background-color: #25d366 !important;
  color: white !important;
}
</style>
