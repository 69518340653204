import api from "@/services/api";
import { OnboardingDetailType } from "@/types/onboarding/Onboarding";

class GetOnboarding {
  execute = async (id: string) => {
    const onboarding = await api().get(`/customer/${id}`);
    return onboarding.data as OnboardingDetailType;
  };
}

export { GetOnboarding };
