import api from "@/services/api";

import { CategoryType } from "@/types/category/Category";

class GetAllCategories {
  execute = async () => {
    const categories = await api().get(`/partners/categories`);
    return categories.data as CategoryType[];
  };
}

export { GetAllCategories };
