<template>
  <div>
    <div class="header">
      <title-vue title="Lista de BRBonus" />
    </div>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquise por data ou status"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="brbonus"
        :footer-props="{ 'items-per-page-text': 'brbonus por página' }"
        :search="search"
        no-data-text="Nenhum BRBonus encontrado"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ formatDateRef(item.date) }}</td>
              <td class="d-block d-sm-table-cell">{{ formatDate(item.date) }}</td>
              <td align="right" class="d-block d-sm-table-cell">
                <status-badge-vue :status="item.status" />
              </td>
              <td align="right" class="d-block d-sm-table-cell">
                <v-icon size="24" color="#e5183d" @click="handleDetailBrbonus(item.date)"
                  >mdi-menu</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TitleVue from "@/components/title/Title.vue";
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";

import { BrbonusListType } from "@/types/brbonus/Brbonus";

import { GetAllBrbonus } from "@/services/brbonus/GetAllBrbonus";

import { format, parseISO, subMonths } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  createdAt: string;
}

export default defineComponent({
  components: { TitleVue, StatusBadgeVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      showModal: false,
      showModalRemove: false,
      brbonus: [] as BrbonusListType[],
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Mês de referência",
          value: "date",
        },
        {
          text: "Data de movimentação",
          value: "date",
        },
        { text: "Status", align: "right", value: "status" },
        {
          text: "Ações",
          align: "end",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async handleLoadBrbonus() {
      this.loading = true;
      const getAllBrbonus = new GetAllBrbonus();
      const brbonus = await getAllBrbonus.execute();
      this.brbonus = brbonus;
      this.loading = false;
    },
    handleDetailBrbonus(date: string) {
      this.$router.push(`/brbonus-distribution/${date}`);
    },
    handlePhasesSystemLive() {
      this.$router.push(`/phases/live`);
    },

    formatDate: (date: string): string => {
      const dateFormated = format(parseISO(date), "dd/MM/yyyy");
      return dateFormated;
    },
    formatDateRef: (date: string): string => {
      const parsedDate = parseISO(date);
      const modifiedDate = subMonths(parsedDate, 1);
      const month = modifiedDate.getMonth() + 1;

      const monthString = month === 0 ? "12" : String(month).padStart(2, "0");

      const year = modifiedDate.getFullYear();

      return `${monthString}/${year}`;
    },
  },
  mounted() {
    this.handleLoadBrbonus();
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
