<template>
  <div>
    <div class="header">
      <title-vue title="Lista de categorias" />
      <v-btn dark color="#e5183d" @click="handleNewCategory">Nova categoria</v-btn>
    </div>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquise pelo nome" />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :header-props="{ sortByText: 'Filtrar por' }"
        :headers="headers"
        :items="categories"
        :items-per-page="10"
        :footer-props="{ 'items-per-page-text': 'categorias por página' }"
        :search="search"
        no-data-text="Nenhuma categoria encontrado"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ item.name }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <form-category-vue v-if="showModal" :showModal="showModal" :closeModal="handleCloseModal" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TitleVue from "@/components/title/Title.vue";

import { CategoryType } from "@/types/category/Category";
import { GetAllCategories } from "@/services/categories/GetAllCategories";

import FormCategoryVue from "./FormCategory/FormCategoryView.vue";

interface SortItem {
  name: string;
}

export default defineComponent({
  components: { TitleVue, FormCategoryVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      showModal: false,
      categories: [] as CategoryType[],
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
      ],
    };
  },
  methods: {
    async handleLoadCategories() {
      this.loading = true;
      const getAllCategories = new GetAllCategories();
      const categories = await getAllCategories.execute();
      this.categories = categories;
      this.loading = false;
    },
    handleCloseModal() {
      this.handleLoadCategories();
      this.showModal = false;
    },
    handleNewCategory() {
      this.showModal = true;
    },
  },
  mounted() {
    this.handleLoadCategories();
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
