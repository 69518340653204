import api from "@/services/api";
import { OnboardingDetailType } from "@/types/onboarding/Onboarding";

class UpdateOnboarding {
  execute = async (id: string, status: string, reason?: string) => {
    if (status == "ACTIVE") {
      const onboarding = await api().put(`/customer/${id}`, {
        status: status,
      });
      return onboarding.data as OnboardingDetailType;
    } else {
      const onboarding = await api().put(`/customer/${id}`, {
        status: status,
        reason: reason,
      });
      return onboarding.data as OnboardingDetailType;
    }
  };
}

export { UpdateOnboarding };
