import api from "@/services/api";


class UpdateOnboardingPartner {
    execute = async (id: string, status: string) => {
        const partnerOnboarding = await api().put(`/partners/change-status/${id}/${status}`);
        return partnerOnboarding.data
    }
    
}

export { UpdateOnboardingPartner }