<template>
  <v-container>
    <title-vue title="Detalhes do Consumidor" />

    <v-row class="mt-2">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <p class="subtitle-2">Dados pessoais</p>
            <v-row>
              <v-col cols="6">
                <title-subtitle-vue label="Nome completo" :value="name" />
              </v-col>
              <v-col cols="2">
                <title-subtitle-vue label="Data de nascimento" :value="birthdate" />
              </v-col>
            </v-row>
            <p class="subtitle-2 mt-4">Dados de contato</p>
            <v-row>
              <v-col cols="4">
                <title-subtitle-vue label="Email" :value="email" />
              </v-col>
              <v-col cols="2">
                <title-subtitle-vue label="Telefone" :value="formatPhone(phone)" />
              </v-col>
            </v-row>
            <p class="subtitle-2 mt-4">Dados de endereço</p>
            <v-row>
              <v-col cols="4">
                <title-subtitle-vue label="Logradouro" :value="street" />
              </v-col>
              <v-col cols="2">
                <title-subtitle-vue label="Número" :value="addressNumber" />
              </v-col>
              <v-col cols="3">
                <title-subtitle-vue label="Bairro" :value="neighborhood" />
              </v-col>
              <v-col cols="3">
                <title-subtitle-vue label="Complemento" :value="complement" />
              </v-col>
              <v-col cols="4">
                <title-subtitle-vue label="CEP" :value="formatZipCod(zipCode)" />
              </v-col>
              <v-col cols="4">
                <title-subtitle-vue label="Cidade" :value="cityName" />
              </v-col>
              <v-col cols="4">
                <title-subtitle-vue label="Estado" :value="state" />
              </v-col>
            </v-row>
            <p class="subtitle-2 mt-4">Saldos</p>
            <v-row>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="Saldo atual" :value="formatPrice(balance.current)" />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue
                  label="Saldo acumulado"
                  :value="formatPrice(balance.accumulated)"
                />
              </v-col>
              <v-col cols="12" md="4">
                <title-subtitle-vue label="BRP" :value="balance.brpoints" />
              </v-col>
            </v-row>
            <p class="subtitle-2 mt-4">Documentos</p>
            <v-row>
              <v-col cols="12">
                <title-subtitle-vue label="CPF" :value="formatDocument(document)" />
              </v-col>
              <v-col cols="4">
                <p class="label">Selfie</p>
                <v-img
                  width="250"
                  height="250"
                  :src="selfImage"
                  alt="Descrição da imagem"
                  aspect-ratio="2.5"
                ></v-img>
              </v-col>

              <v-col cols="4">
                <p class="label">Documento frente</p>
                <v-img
                  width="250"
                  height="200"
                  :src="frontDocumentImage"
                  alt="Descrição da imagem"
                  aspect-ratio="2.5"
                ></v-img>
              </v-col>
              <v-col cols="4">
                <p class="label">Documento verso</p>
                <v-img
                  width="250"
                  height="200"
                  :src="backDocumentImage"
                  alt="Descrição da imagem"
                  aspect-ratio="2.5"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="handleCancel">Voltar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TitleVue from "@/components/title/Title.vue";
import TitleSubtitleVue from "@/components/titleSubtitle/TitleSubtitle.vue";
import { GetOnboarding } from "@/services/onboardings/GetOnboarding";
import { format } from "date-fns";
export default defineComponent({
  components: { TitleVue, TitleSubtitleVue },
  props: ["mode", "pClient"],
  data() {
    return {
      loading: false,
      id: "",
      name: "",
      birthdate: "",
      status: "",
      createdAt: "",

      phone: "",
      email: "",

      street: "",
      addressNumber: "",
      neighborhood: "",
      complement: "",
      zipCode: "",
      cityName: "",
      state: "",

      balance: {
        accumulated: 0,
        brpoints: 0,
        current: 0,
      },

      document: "",
      frontDocumentImage: "",
      backDocumentImage: "",
      selfImage: "",
      updatedAt: "",
    };
  },
  methods: {
    async handleLoadCustomer() {
      const getCustomer = new GetOnboarding();
      const customer = await getCustomer.execute(this.$route.params.id);

      this.id = customer.id || "";
      this.name = customer.name || "";
      this.birthdate = customer.birthdate || "";

      this.createdAt = customer.createdAt || "";

      this.phone = customer.phone || "";
      this.email = customer.email || "";

      this.street = customer.address.street || "";
      this.addressNumber = customer.address.addressNumber || "";
      this.neighborhood = customer.address.neighborhood || "";
      this.complement = customer.address.complement || "";
      this.zipCode = customer.address.zipCode || "";
      this.cityName = customer.address.cityName || "";
      this.state = customer.address.state || "";

      this.balance.accumulated = customer.balance.accumulated;
      this.balance.brpoints = customer.balance.brpoints;
      this.balance.current = customer.balance.current;

      this.document = customer.document || "";
      this.backDocumentImage = customer.backDocumentImage || "";
      this.frontDocumentImage = customer.frontDocumentImage || "";
      this.selfImage = customer.selfImage || "";
    },

    handleCancel() {
      this.$router.back();
    },
    formatPrice(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(value);
      return formattedValue;
    },
    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },
  mounted() {
    this.handleLoadCustomer();
  },
});
</script>
<style scoped>
.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}
</style>
